.visionTitle {
    font-weight: bold !important;
    font-size: 1.1rem !important;

    margin: 6px !important;
}

.visionDescription{
    color:#686868;
    font-size: 0.85rem !important;
    line-height: 1.2 !important;
    margin: 6px !important;
}

.securityModuleIcon{
    width: 35px;
    height: 35px;
}

.MuiTimelineItem-alignAlternate:nth-child(odd) .securityModuleContainer{
    float: right;
    margin-top: -38px;

}

.MuiTimelineItem-alignAlternate:nth-child(even) .securityModuleContainer{
    float: left;
    margin-top: -38px;
}

.listItemLink{
    min-width: 350px;
}

.relatedRequestTitle{
    margin-top: 35px !important;
}

.MuiTimelineItem-missingOppositeContent:before{
    flex:0 !important;
}

.buttonMilestoneDialog{
    margin-left:auto !important;
    background-color:#fff !important;
}

.buttonMilestoneDialog span{

    color: rgba(37, 124, 225, 1)!important; 
    
}

.buttonMilestoneDialog :hover{
    text-decoration: underline !important;
}
.cardMilestoneItem{
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}

.cardMilestoneFeatureItem{
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}

.chipComplete{
    background-color: rgb(46, 125, 50) !important;
    color: #fff !important;
}

.chipPlanned{
    background-color: rgba(37, 124, 225, 1) !important;
    color: #fff !important;
}

.chipOpen{
    background-color: #e0e0e0 !important;
}

.milestoneFeatureHeader{
    float:left;
}

.milestoneFeatureHeaderTitle{
    padding-top:10px;
}

.chipGovernance{
    color: rgba(37, 124, 225, 1) !important;
}

.cardMilestoneFeatureItemBox {
    position: relative;
}
