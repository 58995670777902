/*
███    ███  █████  ████████ ███████ ██████  ██  █████  ██          ██    ██ ██ 
████  ████ ██   ██    ██    ██      ██   ██ ██ ██   ██ ██          ██    ██ ██ 
██ ████ ██ ███████    ██    █████   ██████  ██ ███████ ██          ██    ██ ██ 
██  ██  ██ ██   ██    ██    ██      ██   ██ ██ ██   ██ ██          ██    ██ ██ 
██      ██ ██   ██    ██    ███████ ██   ██ ██ ██   ██ ███████      ██████  ██ 
                                                                               
██    ██ ██   ██    ██  ██     ██████                                          
██    ██ ██   ██   ███ ███    ██  ████                                         
██    ██ ███████    ██  ██    ██ ██ ██                                         
 ██  ██       ██    ██  ██    ████  ██                                         
  ████        ██ ██ ██  ██ ██  ██████                                          




value         |0px     600px    960px    1280px   1920px
key           |xs      sm       md       lg       xl
screen width  |--------|--------|--------|--------|-------->
range         |   xs   |   sm   |   md   |   lg   |   xl
*/








/*
██     ██ ██ ███    ██ ██████   ██████  ██     ██ 
██     ██ ██ ████   ██ ██   ██ ██    ██ ██     ██ 
██  █  ██ ██ ██ ██  ██ ██   ██ ██    ██ ██  █  ██ 
██ ███ ██ ██ ██  ██ ██ ██   ██ ██    ██ ██ ███ ██ 
 ███ ███  ██ ██   ████ ██████   ██████   ███ ███  
*/
html{ margin:0; padding:0; background-color:#f4f4f4; }
body { margin: 0; padding-bottom:4em; font-family: "Roboto", 'Open Sans', BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }
html.scrolled, body{background-color: #f4f4f4;}













/*
██████   █████  ████████  █████      ██████  ██ ███████ ██████  ██       █████  ██    ██ 
██   ██ ██   ██    ██    ██   ██     ██   ██ ██ ██      ██   ██ ██      ██   ██  ██  ██  
██   ██ ███████    ██    ███████     ██   ██ ██ ███████ ██████  ██      ███████   ████   
██   ██ ██   ██    ██    ██   ██     ██   ██ ██      ██ ██      ██      ██   ██    ██    
██████  ██   ██    ██    ██   ██     ██████  ██ ███████ ██      ███████ ██   ██    ██    
*/

/*
  Avatar
*/
.MuiAvatar-colorDefault{ background-color:#fafafa; }

/*
  Typography
*/
.MuiTypography-root{  }

.MuiTypography-h1, .MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4,.MuiTypography-h5, .MuiTypography-h6,
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{font-size:1.4rem !important;font-weight:400 !important;line-height:1.1 !important;letter-spacing:-.008em !important;}
/* .MuiTypography-h1{ font-size:1.8rem !important; letter-spacing:-.05em !important; }
.MuiTypography-h2{ font-size:1.5rem !important; }
.MuiTypography-h3{ font-size:1.4rem !important; }
.MuiTypography-h4{ font-size:1.3rem !important; }
.MuiTypography-h5{ font-size:1.2rem !important; }
.MuiTypography-h6{ font-size:1.1rem !important; }  */

.MuiTypography-subtitle1{}
.MuiTypography-subtitle2{}

.MuiTypography-body1{}
.MuiTypography-body2{}

.MuiTypography-button{}
.MuiTypography-caption{}

.MuiTypography-gutterBottom{}
.MuiTypography-displayBlock{}
.MuiTypography-overline{}

hr.MuiDivider-root{ margin:10px 0; }


img{display:block;max-width:100%;height:auto;}

/*
    Situational
*/
.MuiTypography-root + .iframe-container{margin: 10px 0;}











/*
██ ███    ██ ██████  ██    ██ ████████ ███████ 
██ ████   ██ ██   ██ ██    ██    ██    ██      
██ ██ ██  ██ ██████  ██    ██    ██    ███████ 
██ ██  ██ ██ ██      ██    ██    ██         ██ 
██ ██   ████ ██       ██████     ██    ███████                                             
*/
/*
  Button
*/
/*Base & Default*/
button.MuiButtonBase-root{}
button.MuiButton-root{ padding:4px 14px; background-color:#cdd2d5; }
button.MuiButton-root:hover{ background-color:#f4f4f4; }
button.MuiButton-contained{}
button .MuiButton-label{ color:#051c2c; }
button .MuiTouchRipple-root{}
/*Primary + primary link*/
button.MuiButton-containedPrimary,.MuiButton-containedPrimary{ background-color:#2a7de1; }
button.MuiButton-containedPrimary .MuiButton-label,a.MuiButton-containedPrimary .MuiButton-label{ color:#fff; }
button.MuiButton-containedPrimary:hover,a.MuiButton-containedPrimary:hover{ background-color:#24a7ff; }
/*Secondary*/
button.MuiButton-containedSecondary{}
button.MuiButton-containedSecondary:hover{}
/*Disabled*/
button.Mui-disabled{ background-color:#828d96; }
/*Link*/
a.MuiButton-containedPrimary{}
















/*
███    ██  █████  ██    ██ ██  ██████   █████  ████████ ██  ██████  ███    ██ 
████   ██ ██   ██ ██    ██ ██ ██       ██   ██    ██    ██ ██    ██ ████   ██ 
██ ██  ██ ███████ ██    ██ ██ ██   ███ ███████    ██    ██ ██    ██ ██ ██  ██ 
██  ██ ██ ██   ██  ██  ██  ██ ██    ██ ██   ██    ██    ██ ██    ██ ██  ██ ██ 
██   ████ ██   ██   ████   ██  ██████  ██   ██    ██    ██  ██████  ██   ████ 
*/

@media and (min-width: 960px){
  .MuiTab-textColorPrimary:hover{ color:#051c2c; }
  /* .MuiTab-textColorPrimary.Mui-selected{} */
}
















/*
██       █████  ██    ██  ██████  ██    ██ ████████ 
██      ██   ██  ██  ██  ██    ██ ██    ██    ██    
██      ███████   ████   ██    ██ ██    ██    ██    
██      ██   ██    ██    ██    ██ ██    ██    ██    
███████ ██   ██    ██     ██████   ██████     ██    
*/
.MuiContainer-root{ padding:0 !important; }
.MuiPaper-root{padding:5px;}
.MuiGrid-item{padding:10px;}
.MuiGrid-container{padding:0;}
.MuiPaper-outlined{ border-color:#cdd2d5; }

/* .MuiPaper-rounded .MuiGrid-item{padding:10px;} */
/* .container.MuiGrid-item, .MuiGrid-container{ padding:0; } */
/*
    header
*/
/*header*/
header>.MuiTabs-root{ border-bottom:1px inset rgba(5,28,44,.2); }
header img.jss2{ padding:6px !important; }
header .jss10 button{ position:relative; top:-10px; padding:0; }
header .jss10 button svg{ fill:#919ba2; width:18px; }
header .MuiToolbar-root h1{ margin: -5px 0 0 0 !important; padding:0 4px 0 0 !important; line-height:1; font-weight:bold !important; font-size:26px !important; letter-spacing:-0.02em !important; }
header .MuiInputBase-input{ background:#f4f4f4; border:1px solid #eaeaea; border-radius:3px; }
header .jss5{ z-index:1;top:8px; }
/* #root > div > header{border-bottom:1px solid #f0f1f2;} */
#root > div > header, header.MuiAppBar-colorDefault{ background-color:#fff; box-shadow:none; }
/*
    content
*/
.content{ padding:16px; }
.content > .container{ }


@media (min-width: 600px){
    .content{ flex-direction:row; /*padding:24px;*/ }
}
.embed .content{ padding:0; }

/*
    MuiTabs-flexContainerVertical
*/
.MuiTabs-flexContainerVertical button .MuiTab-wrapper{ align-items:flex-start; }
.MuiTabs-flexContainerVertical button.Mui-selected .MuiTab-wrapper{ color:#2a7de1; }


/*
  Release containers
*/
.SelectReleaseContainer{
  display:flex;
  flex-direction: row-reverse;
  margin-bottom: -52px !important;
}


/*
  Release tiles
*/
.MuiContainer-root{}
.MuiContainer-root .gridButtonGroup{}
.MuiContainer-root .MuiGrid-container{}

.MuiGrid-item{}
.MuiGrid-item .media{ height:218px; }
.MuiGrid-item .MuiCard-root{ box-shadow:none; border:1px solid #e9e9e9; }
.MuiGrid-item .card-info{}
.MuiCardContent-root > .card-info + .card-info{ display:flex; justify-content:space-between; }
.MuiGrid-item .card-info > h1{ margin-bottom:0; line-height:1; line-height:1.2; height:55px; max-height:50px; max-width:92%; }
.MuiGrid-item .card-info p{ margin-bottom:0; }
.MuiGrid-item p.card-details{ margin-top:16px; }
.MuiGrid-item .card-info > .MuiIcon-root{ display:none; }
.MuiGrid-item .card-info > .MuiTypography-body2{font-size:0.8rem; }
/* .MuiGrid-item .card-info > .card-info-score{ color:#79838d; } */
.MuiGrid-item .card-info > .card-info-score span:first-child{ transform: scale(.75) translate(-50%, 0%); } /*transform: scale(.75) translate(0, 75%);*/
.MuiGrid-item .card-info > .card-info-score .MuiBadge-badge{ transform: scale(1) translate(20%, -20%); font-weight:bold; background-color:#79838d; } /*transform: scale(1) translate(50%, 30%);*/
.MuiChip-sizeSmall.MuiChip-root{ color:#525df9; font-size:0.9rem; border-radius:3px; background-color:#fff; }

@media screen and (min-width: 960px){
  .MuiTabs-scrollButtons{ display:none; }
  .MuiToggleButtonGroup-root .MuiToggleButton-root{ width:32px; height:32px; }
  .MuiGrid-item.MuiGrid-grid-lg-3{ flex-grow:0; max-width:33.33%; flex-basis:33.33%; }/* 3 kolommen, denk met classes fixen? */
}

/*
    Release List
*/
/* .releaseGridItem{padding:1em 0; } */
/* .releaseGridItem + .releaseGridItem{ margin-top:1em; } */
/* .releaseGridItem .releaseTitle{ margin:16px 0 8px 0.2em; font-size:24px; letter-spacing:0.024em; font-weight:500; text-transform:uppercase; } */
/* .releaseGridItem .releaseTitle + hr{ margin-bottom:1em; } */
.domainGridItem {}
.domainGridItem .domainTitle{ font-weight:500; margin-bottom: 8px;}
.feature-list{ margin:1em 0 0.5em; }
.domainTitle + .feature-list li:first-child{ margin-top:1em; }
.feature-list li{ display:flex; padding-left:0; padding-right:0; line-height:1.2 !important;  }
.feature-list .MuiAvatar-root{margin-right:16px;border-radius:6px; border: 1px solid #cdd2d5;}
.feature-list img{/* border: 9px solid red !important; */}
.feature-list a{ align-self:center; }
.feature-list .MuiSvgIcon-root{ margin-left:auto; fill:#465763; }
.feature-list .MuiChip-root{ margin-left:auto; fill:#465763; }
.feature-list .MuiAvatar-circle > .MuiSvgIcon-fontSizeLarge{ margin-left:0; fill:#2a7de1; }
.domainVideoContainer :hover{cursor: pointer;}
.domainVideoContainer{cursor: pointer; margin-bottom: 8px;}
.domainVideo{display:flex}
.domainVideo img{margin:3px;}
.domainVideo .domainVideoTitle{margin-left:5px; margin-top:3px; margin-bottom: 0; font-weight:bold; }
.domainVideo .domainVideoSubTitle{margin-left:5px; margin-top:0; margin-bottom: 0; font-size:0.8rem;}
.domainVideo .domainVideoSubTitle p :hover{text-decoration:none  !important;}


/*
  Webinar time list
*/
.webinarTimeList{  }
.webinarTimeList > li{ display:flex; justify-content:space-between; padding:2px 0; max-width:360px; }
.webinarTimeList li p:first-child{ font-weight:bold; }
.webinarTimeList li p + p{ font-size:90%; }




/*
  iFrame Video
*/
.iframe-container{position:relative;overflow:hidden;width: 90%;padding-top: 47%; border-radius:3px;} /*100x54.25*/
.iframe-container img{ position:absolute; top:0; left:0; }
.iframe-container iframe{ position:absolute; top:0; left:0; bottom:0; right:0; width:100%; height:100%; }


/*
  PopUp
*/
.popup-welcome .h1, .popup-welcome .h2{ font-weight:bold; line-height:1.1; }
.popup-welcome .h1{ color:#2a7de1; font-size:34px; letter-spacing:-0.01em; margin-top:0.25em; }
.popup-welcome .h1 + .h2{ margin-bottom:0.85em; }
.popup-welcome .h2{ font-size:24px; }
.popup-welcome .MuiTypography-root:not(.h1):not(.h2){ max-width:600px; } 

.popup-welcome .popup-dest{ margin-bottom:2em; padding-bottom:1em; border-bottom:1px solid #919ba2; }
.popup-welcome .popup-dest > .MuiGrid-item{ padding:1em 1em 0; }
.popup-welcome .popup-dest > .MuiGrid-item > p.MuiTypography-body1{ padding:1em; text-transform:uppercase; letter-spacing:-0.01em; font-weight:500; color:#2a7de1; text-align:center; border-bottom:2px solid #2a7de1; }

.popup-welcome .popup-dest > .MuiGrid-item ul{ padding-left:1.1em; }
.popup-welcome .popup-dest > .MuiGrid-item .MuiListItemText-root{ display:list-item; list-style-type:square; margin:0.5em 0; }

@media screen and (max-width: 959px){
  .popup-welcome .popup-dest > .MuiGrid-item{ padding:0.5em 0; }
  .popup-welcome .popup-dest > .MuiGrid-item > p.MuiTypography-body1{ padding:0 0 1em 0; text-align:left; }
  .popup-welcome .popup-dest > .MuiGrid-item > p.MuiTypography-body1 span{ position:relative; top:6px; margin-right:0.25em; }
}

@media screen and (min-width: 960px){
  .popup-welcome .popup-dest > .MuiGrid-item .MuiListItemText-root{ margin:0.75em 0; letter-spacing:-0.01em; }
  .popup-welcome .popup-dest > .MuiGrid-item > p.MuiTypography-body1 span{ width:100%; }
  .popup-welcome .popup-dest{ margin-bottom:2em; padding-bottom:2em; }
  .popup-welcome .popup-dest + .h2 + p{ float:left; }
  .popup-welcome p + a{ float:right; line-height:0; }
}
@media screen and (min-width: 960px) and (max-width: 1100px){
  .popup-welcome .popup-dest > .MuiGrid-item > p.MuiTypography-body1{ font-size:0.85rem;  }
}




/*
  Hovers for desktop only
*/
@media screen and (min-width: 960px){
  header .jss10 button:hover svg{ fill:#2a7de1; }
  .domainGridItem ul a:hover{ color:#2a7de1; }
  a, button, .feature-list .MuiAvatar-circle > .MuiSvgIcon-fontSizeLarge{ cursor:pointer !important; }
  .feature-list .MuiAvatar-circle > .MuiSvgIcon-fontSizeLarge:hover{  }
}