
.media{
    height: 160px;
}

.card-details{
    height:55px;
    overflow:hidden;
    text-overflow: ellipsis;
}

.gridContainer{
    margin-top: 10px;
}

.card-info{
    display:flex;
}

.card-info-score{
    margin-left:auto;
}

.card-info-eta{
    padding-top:3px;
}

.card-info-category-chip{
    margin-left:auto !important;
}

.gridButtonGroup{
    margin-bottom:10px;
}

.listItemLink{
    margin-left:0;
}

.gridDivider {
    margin-bottom: 10px !important;
}

.domainListPaperContainer {
   padding:6px;
}

.feature-list .iconModuleGovernance {
    position:absolute;
    margin:0;
    left:30px;
    top:27px;
    color:rgba(37, 124, 225, 1);
    fill:rgba(37, 124, 225, 1);
}